/**
 *	Styles
 */

@import 'settings/index';
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'vendors/index';
@import 'utilities/index';

// Utilities
@import 'node_modules/bootstrap/scss/utilities/api';

@import 'custom/index';
/* global.css */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
    font-family: 'IBM Plex Sans', sans-serif !important;
}

.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
    filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
    -webkit-filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
}

.floating-button {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1000; 
    cursor: pointer;
}

.MuiChartsAxis-tickLabel {
    font-family: 'IBM Plex Sans', sans-serif !important;
}

.MuiChartsAxis-label {
    font-family: 'IBM Plex Sans', sans-serif !important;
}

#combo-box-demo {
    padding: 0px !important;
}

.leaflet-draw-actions-top {
    display: none !important;
}

.ship-label-line{
    stroke-width: 1.5 !important;
}