//
//  Page Wrapper
//
.page-wrapper {
	display: flex;
	flex: 0 0 100%;
	flex-direction: row;
	padding-right: 0;
	padding-left: 0;
	overflow: hidden;
}